@import "./_mantine.scss";
.ratingContainer {
  gap: 16px;
  @media (max-width: $mantine-breakpoint-sm) {
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
}

.ratingContainerModal {
  gap: 16px;
  @media (max-width: $mantine-breakpoint-sm) {
    gap: 8px;
    justify-content: space-between;
  }
}

.symbolGroup {
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border-radius: 8px;
}

.starSymbol {
  padding: 15px;
  svg {
    width: 25px;
    height: 25px;
  }
}

.reviewText {
  background-color: var(--mantine-color-gray-2);
  border: 4px solid var(--mantine-color-gray-2);
  &:focus {
    border: 4px solid var(--mantine-color-blue-2);
  }
  &[data-invalid] {
    border: 1px solid var(--mantine-color-red-5);
  }
}

.modal {
  background: transparent;
  height: 100%;
  position: relative;
  box-shadow: none;
  @media (max-width: $mantine-breakpoint-sm) {
    background: white;
  }
}

.modalBody {
  background: white;
  border-radius: 1rem;
  padding: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-lg);
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 2px;
  box-shadow: 1px 1px 12px rgba(120, 121, 123, 0.5);
  background-color: var(--mantine-color-blue-8);
  color: white;
  z-index: 202;
  &:hover {
    background-color: var(--mantine-other-blueHover);
    color: white;
  }
  @media (max-width: $mantine-breakpoint-sm) {
    right: 24px;
    top: 9px;
  }
}

.modalContents {
  align-items: center;
}

.headerText {
  padding-top: 32px;
  font-weight: 700;
  font-size: 1.375rem;
  color: var(--mantine-color-blue-8);
  width: 80%;
  line-height: 28px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}

.primaryButton {
  background-color: var(--mantine-color-pink-6);
  span {
    &:after {
      content: '';
      padding-right: 2px; // fix for quotes being cut off
    }
  }
  &:hover {
    background: var(--mantine-other-pinkHover);
  }
}

.secondaryButton {
  background: white;
  color: var(--mantine-color-pink-5);
  border: 1px solid var(--mantine-color-pink-5);
  &:hover {
    background: var(--mantine-other-pinkHover);
    color: var(--mantine-color-white);
    border: 1px solid var(--mantine-other-pinkHover);
  }
}

.error {
  font-weight: 600;
  line-height: 16px;
  font-size: 12px;
  color: var(--mantine-color-red-5);
}

.helperText {
  font-size: var(--mantine-font-size-lg);
  line-height: 24px;
  font-weight: 600;
}
