@import "./_mantine.scss";
.card {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(11, 23, 78, 0.1);
  border-radius: 16px;
  margin: auto;
  margin-top: var(--mantine-spacing-md);
  margin-bottom: var(--mantine-spacing-md);
  color: var(--mantine-color-blue-8);
}

.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.textInfo {
  line-height: 18px;
  font-size: 14px;
  color: var(--mantine-color-blue-8);
}

.checkboxLabel {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  font-size: 16px;
}
.precheckoutFormWrapper {
  padding: 0;
  @media (max-width: 768px) {
    padding: var(--mantine-spacing-xs);
  }
}
.formWrapper {
  padding: 40px 12px;
  @media (max-width: 768px) {
    padding: var(--mantine-spacing-lg);
  }
}

.preCheckoutCard {
  margin-block: var(--mantine-spacing-sm);
}

.dropdown {
  color: var(--mantine-color-blue-8);
  background-color: var(--mantine-color-gray-1);
  border-radius: 8px;
  border: var(--mantine-color-blue-1) solid 4px;
  padding: 0;
}

.option {
  color: var(--mantine-color-blue-8);
  line-height: 20px;
  margin-right: -4px;
  font-weight: 600;

  &:hover {
    background-color: var(--mantine-color-blue-1);
    border-radius: 0;
  }
}

.input {
  background-color: var(--mantine-color-gray-2);
  border-radius: 8px;
  border: none;
  font-weight: 600;
  color: var(--mantine-color-blue-8);
}

.rightSection {
  pointer-events: none;
}

.submitButton {
  &:disabled {
    border: none !important;
    background-color: var(--mantine-color-gray-1) !important;
    color: var(--mantine-color-gray-4) !important;
  }
  font-size: 16px;
  line-height: 22px;
}