@import "./_mantine.scss";
.container {
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0;
    overflow: hidden;
  }
}
.pageSection {
  width: 100%;
  @media (max-width: $mantine-breakpoint-sm) {
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
  }
}
.bottomSection {
  margin-bottom: 5.5rem;
}
// --- Shared styles ---
.soldOut {
  color: #971b00;
  font-weight: 700;
  font-size: var(--mantine-font-size-xs);
}
.oneSpotLeft {
  &::after {
    content: '•';
    color: #fb54ae;
    display: inline-block;
    margin-left: var(--mantine-spacing-xs);
  }
}
.activityDetailsLink {
  font-weight: 600;
  color: var(--mantine-color-blue-5);
  line-height: 16px;
  cursor: pointer;
  font-size: var(--mantine-font-size-sm);
  &:hover {
    text-decoration: underline;
  }
  margin-bottom: var(--mantine-spacing-lg);

  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-xs);
  }
}
.locationLink {
  font-size: var(--mantine-font-size-xs);
  color: var(--mantine-color-gray-8);
  margin-bottom: 0;
}
.supplierDetailsLink {
  a {
    color: var(--mantine-color-blue-5);
    text-decoration: none;
  }
  @media (max-width: $mantine-breakpoint-xs) {
    width: 100%;
  }
}
.supplierDetailsButton {
  border-radius: 4px;
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-gray-6);
  padding: 4px;
  line-height: 0.75rem;
  height: 1.5rem;
  min-width: 0;
  display: flex;
  align-items: center;
  width: fit-content;

  span {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.sectionText {
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  color: var(--mantine-color-gray-6);
  //styles for embedded HTML rendered directly from rich text editor
  margin-block: 0;
  ol {
    margin-block: 0;
  }
  ul {
    margin-block: 0;
  }
  li {
    margin-top: 0.125rem;
    p {
      margin-block: 0;
    }
  }
}
.locationContainer {
  @media (max-width: $mantine-breakpoint-sm) {
    border-bottom-right-radius: var(--mantine-radius-md);
    border-bottom-left-radius: var(--mantine-radius-md);
    box-shadow: 0 6px 6px #eaebef;
    padding-left: var(--mantine-spacing-lg);
  }
}
.pricingBadges {
  display: flex;
  gap: var(--mantine-spacing-xl);
  color: var(--mantine-color-blue-8);
}

.dateRangeAndSoldOut {
  margin-bottom: var(--mantine-spacing-lg);
}
.timeDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.detailsItem {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: var(--mantine-spacing-xs);
  padding-top: var(--mantine-spacing-md);
  p {
    font-size: var(--mantine-font-size-md);
    margin: 0;
    margin-left: var(--mantine-spacing-xs);
    color: var(--mantine-color-blue-8);
  }
  svg {
    color: var(--mantine-color-blue-5);
    text-align: center;
  }
}
.sessionSpots {
  margin-right: 0.5rem;
  font-weight: 600;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-md);
}

.disablePointer {
  cursor: default;
}
// ---- ActivityDetails styles ----
.activityHeader {
  margin-top: 0;
  h2 {
    font-size: var(--mantine-font-size-sm);
    line-height: 18px;
    font-weight: 500;
    color: var(--mantine-color-blue-8);
    margin-top: 4px;
  }
  @media (max-width: $mantine-breakpoint-sm) {
    padding-left: var(--mantine-spacing-md);
    h1 {
      font-size: 24px;
      line-height: 24px;
    }
  }
}
.icon {
  color: var(--mantine-color-blue-5);
}
.bookingsTabsList {
  border-bottom: 0;
  width: 100%;
  background-color: var(--mantine-color-gray-1);
  border-radius: 12px 12px 0 0;

  &::before {
    content: none;
  }
}

.bookingsTab {
  color: var(--mantine-color-blue-8);
  font-weight: 400;
  border: none;
  width: 50%;
  font-size: var(--mantine-font-size-md);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);
  background: transparent;
  &:hover {
    background: none;
  }
  &[data-active] {
    background-color: white;
    font-weight: 700;
  }
  &[aria-selected='false'] {
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 12px rgba(11, 23, 78, 0.03);
    margin-top: 4px;
  }
}
.bookingsTabOneTabOnly {
  color: var(--mantine-color-blue-8);
  border: none;
  font-weight: 700;
  font-size: var(--mantine-font-size-lg);
  padding: 16px 16px 0;
  background: transparent;
  width: 100%;
  justify-content: flex-start;

  &:hover {
    background: none;
  }
  &[data-active] {
    background-color: white;
    // color: var(--mantine-color-blue-8) !important;
  }
}
.sectionHeader {
  font-size: var(--mantine-font-size-lg);
  line-height: 24px;
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  margin-top: var(--mantine-spacing-md);
  margin-bottom: 0;
  //TODO check border radius prop
  border-radius: 0;
  -moz-border-radius: 0;
}
.reviewsWrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 var(--mantine-spacing-sm);
  }
}
.mapWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-radius: var(--mantine-radius-sm);
  overflow: hidden;
}
.mapHeader {
  font-size: var(--mantine-font-size-sm);
  font-weight: 400;
}
.mapInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.imageWrapper {
  border-radius: var(--mantine-radius-sm);
  overflow: hidden;
  max-height: 522px;
  @media (max-width: $mantine-breakpoint-sm) {
    border-radius: 0;
  }
}
.iconsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    gap: var(--mantine-spacing-sm);
    align-items: start;
  }
}
.cardHeader {
  position: relative;
  overflow: hidden;
  border-radius: var(--mantine-radius-sm);
  img {
    width: 100%;
  }
  @media (max-width: $mantine-breakpoint-sm) {
    border-radius: 0;
  }
}
.tabPanel {
  padding: 0 20px var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-md);
    border-radius: 0 0 12px 12px;
  }
}
.calendarTabPanel {
  padding: 0 var(--mantine-spacing-lg) var(--mantine-spacing-md);

  @media (max-width: 991px) and (min-width: $mantine-breakpoint-sm) {
    padding: 0 var(--mantine-spacing-lg) var(--mantine-spacing-md);
    max-width: 92vw;
  }
}
.spoilerControl {
  color: var(--mantine-color-blue-6);
  font-weight: 400;
  font-size: var(--mantine-font-size-xs);
  line-height: 16px;
  padding-top: var(--mantine-spacing-xs);
}
// --- TimesAndAvailability styles ---
.detailsCenter {
  svg {
    margin-right: 2px;
    margin-left: 2px;
  }
}
// --- Days of week dropdown ---
.accordionItem {
  margin-top: var(--mantine-spacing-xl);
  border: 0;
  padding: 0;
}
.accordionContent {
  padding: 0;
}
.accordionControl {
  color: var(--mantine-color-blue-8);
  border: 0;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-md);

  &::placeholder {
    color: var(--mantine-color-blue-8);
  }
}
.accordionPanel {
  padding: 0;
}
.weekdayButton {
  width: 100%;
  padding: 32px;

  &:hover {
    background-color: var(--mantine-color-gray-1);
  }
}
.dropdownRoot {
  margin-top: var(--mantine-spacing-lg);
  margin-bottom: var(--mantine-spacing-md);
}
.dropdown {
  margin-top: 0;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
}
.input {
  color: var(--mantine-color-blue-8);
  border: 0;
  background-color: var(--mantine-color-gray-2);
  padding: 9px 16px;
  font-size: var(--mantine-font-size-md);
  height: 40px;
  border-radius: 8px;

  &::placeholder {
    color: var(--mantine-color-blue-8);
  }
}
.rightSection {
  pointer-events: none;
  margin-right: var(--mantine-spacing-sm);
}

.rootBadge {
  padding: 2px 4px;
  background-color: var(--mantine-color-blue-8);
  color: var(--mantine-color-white);
  border-radius: 4px;
  text-transform: none;
}
.innerBadge {
  font-size: 10px;
  font-weight: 700;
}
.trialBadge {
  background-color: var(--mantine-color-green-6);
  color: var(--mantine-color-white);
}
.paidTrialBadge {
  background-color: var(--mantine-color-blue-8);
  color: var(--mantine-color-white);
}
.onlineBadge {
  background: white;
  color: var(--mantine-color-gray-6);
  border-radius: 4px;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  height: auto;
}
.onlineIcon {
  margin-right: var(--mantine-spacing-xs);
}
.locationPin {
  path {
    fill: var(--mantine-color-pink-5);
  }
  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-sm);
  }
}
.enquireButton {
  font-weight: 700;
  padding: 0 var(--mantine-spacing-xs);
  min-width: 120px;
  background-color: var(--mantine-color-lime-5);
  color: var(--mantine-color-blue-8);
  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-8);
  }
}
.dot {
  @media (max-width: $mantine-breakpoint-xs) {
    display: none;
  }
}
.badge {
  background: var(--mantine-color-red-8);
  color: var(--mantine-color-red-1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  text-transform: none;
  font-size: var(--mantine-font-size-xs);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xs);
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 16px;
}
.limitedSpotsBadge {
  color: white;
  background-color: var(--mantine-color-blue-6);
}
.popularBadge {
  color: white;
  background-color: var(--mantine-color-pink-8);
}
.justAddedBadge {
  color: white;
  background-color: #148867;
}
// Social proof banner
.notificationRoot {
  background: var(--mantine-color-blue-1);
  box-shadow: none;
  margin: var(--mantine-spacing-sm) auto;
  &:before {
    display: none;
  }
}
.notificationTitle {
  color: var(--mantine-color-blue-6);
  font-weight: 600;
  font-size: var(--mantine-spacing-sm);
  line-height: 16px;
  text-align: center;
}
.toKnowSection {
  border-top: 1px solid var(--mantine-color-gray-2);
  padding-bottom: var(--mantine-spacing-xs);
  padding-top: var(--mantine-spacing-xs);
  &:last-child {
    border-bottom: 1px solid var(--mantine-color-gray-2);
  }
}
.toKnowHeader {
  font-size: 14px;
  color: var(--mantine-color-gray-6);
  font-weight: 700;
}
.sticky {
  position: sticky;
  top: 98px;
}

.activityDetailsWrapper {
  box-shadow: 0 6px 6px #eaebef;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  height: auto;
  padding: 0 16px 8px 16px;
}

.title {
  font-size: 24px;
  line-height: 24px;
  color: var(--mantine-color-blue-8);
}

.shareButtonWrapper {
  margin-right: -10px;
}

.subDetails {
  color: var(--mantine-color-blue-8);
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-8);
  font-weight: 700;
  border-radius: 24px;
  padding: var(--mantine-spacing-sm);
  width: 100%;
  height: 48px;
  font-size: 14px;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 13px;
  }

  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-8);
  }
}
