@import "./_mantine.scss";
.discountCode {
  text-transform: capitalize;
}

.sessionCostSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-md) {
    margin-top: var(--mantine-spacing-lg);
  }
}

.discountInput {
  border: none;
  border-bottom: 1px solid rgba(127, 131, 155, 0.3);
  border-radius: 0;
  padding: 0;
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  background: white;
  &:disabled {
    background: white;
  }
}

.rightSection {
  svg {
    color: var(--mantine-color-blue-6);
  }
}

.discountLabel {
  font-weight: 600;
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-sm);
}

.discountError {
  font-weight: 400;
  font-size: var(--mantine-font-size-sm);
}

.discountWrapper {
  margin-bottom: var(--mantine-spacing-xs);
}

.totalPrice {
  margin-top: var(--mantine-spacing-xl);
  white-space: nowrap;
  h5 {
    color: var(--mantine-color-blue-8);
  }
  div {
    font-size: 20px;
  }
}

.description {
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-xs);
  line-height: 16px;
  margin-bottom: var(--mantine-spacing-sm);
  @media (max-width: $mantine-breakpoint-md) {
    margin-bottom: 0;
  }
}

.subsInfoBox {
  background-color: var(--mantine-color-yellow-1);
  border: 1px solid var(--mantine-color-yellow-8);
  border-radius: 4px;
  padding: var(--mantine-spacing-xs);
}
.warningIcon {
  height: auto;
  width: auto;
}

.price {
  color: var(--mantine-color-blue-6);

  &.freeTotal {
    color: var(--mantine-color-pink-6);
  }
}
