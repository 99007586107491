@import "./_mantine.scss";
.enquireButton {
  font-weight: 700;
  padding: 0 var(--mantine-spacing-xs);
  min-width: 120px;
  background-color: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
  svg {
    color: var(--mantine-color-blue-8);
  }
  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-8);
    svg {
      color: white;
    }
  }
}
.cancelCopy {
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-xs);
}
.cancelWrapper {
  margin: 0 auto;
}
.cancelCopyBold {
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-6);
}
