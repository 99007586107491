@import "./_mantine.scss";
.checkboxWrapper {
  padding: 0 8px;
  @media (min-width: $mantine-breakpoint-sm) {
    padding: 0 24px;
    height: 390px;
  }
}

.contentWrapper {
  height: calc(100vh - 25vh - 112px - 124px);
  overflow: scroll;
  padding-bottom: 48px;
  @media (min-width: $mantine-breakpoint-sm) {
    height: calc(390px - 96px);
  }
}

.checkedLabel {
  color: var(--mantine-color-blue-6);
  margin: var(--mantine-spacing-sm) 0;
  font-weight: 600;
  font-size: 12px;
}

.uncheckedLabel {
  color: var(--mantine-color-blue-8);
  margin: var(--mantine-spacing-sm) 0;
  font-weight: 600;
  font-size: 12px;
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  left: 24px;
  right: 24px;
  background-color: white;
  padding-top: 24px;
}
