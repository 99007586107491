@import "./_mantine.scss";
.trialInfoBox {
  border-radius: 4px;
  padding: var(--mantine-spacing-xs);
  color: var(--mantine-color-blue-8);
  margin-top: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-md) {
    margin-top: var(--mantine-spacing-lg);
  }
}
.paidTrialBox {
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-6);
}
.freeTrialBox {
  background-color: var(--mantine-color-yellow-1);
  border: 1px solid var(--mantine-color-yellow-8);
}
