@import "./_mantine.scss";
.root {
  padding-bottom: calc(var(--mantine-spacing-sm) * 2);
  margin-block: var(--mantine-spacing-xl);

  &:hover {
    .controls {
      opacity: 1;
    }
  }
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    margin-top: 0;
  }
}

.controls {
  transition: opacity 150ms ease;
  opacity: 0;
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--mantine-spacing-lg);

  @media (max-width: $mantine-breakpoint-sm) {
    transition: none;
    opacity: 1;
    top: 80% !important;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    width: 62px;
    background: var(--mantine-color-gray-7);
    opacity: 0.4;
    border: transparent;
    border-radius: 50%;
    &:hover {
      background: var(--mantine-color-pink-6);
      opacity: 1;
      transition: opacity 150ms ease;
    }
    svg {
      color: white;
      width: 26px;
      height: 26px;
    }
    @media (max-width: $mantine-breakpoint-sm) {
      height: 43px;
      width: 43px;
      background: var(--mantine-color-pink-6);
      opacity: 1;
    }
  }
}

.indicators {
  @media (max-width: $mantine-breakpoint-sm) {
    bottom: 0;
  }
  text-align: center;
  bottom: -10%;
}

.indicator {
  border-radius: 50%;
  background: var(--mantine-color-gray-2);
  height: 12px;
  width: 12px;
  &[data-active] {
    background: var(--mantine-color-lime-5);
    height: 10px;
    width: 10px;
    margin-top: 1px;
  }
}

.carouselCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  border-radius: var(--mantine-radius-lg);
  box-shadow: 0px 5px 25px 3px rgba(0, 0, 0, 0.15);
  margin-top: var(--mantine-spacing-md);
  margin-bottom: var(--mantine-spacing-xl);
  margin-left: var(--mantine-spacing-xs);
  height: 275px;
  width: 257px;
  @media (max-width: $mantine-breakpoint-sm) {
    margin-left: 2.15rem;
    width: 85%;
    width: 257px;
  }
}

.cardHeading {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  padding-left: var(--mantine-spacing-md);
  padding-top: var(--mantine-spacing-xs);
  padding-bottom: 2px;
}

.cardDescription {
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-sm);
  padding-bottom: var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: var(--mantine-spacing-sm);
  }
}

.linkWrapper {
  text-decoration: none;
}
