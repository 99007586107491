@import "./_mantine.scss";
.modalContent {
  @media (max-width: $mantine-breakpoint-sm) {
    height: auto;
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0 !important;
    padding-bottom: 20px;
  }
}

.modalTitle {
  color: var(--mantine-color-blue-5);
}
