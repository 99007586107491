@import "./_mantine.scss";
.button {
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-gray-6);
  &:hover {
    background-color: var(--mantine-color-blue-5);
    color: var(--mantine-color-blue-0);
  }
}

.selectedButton {
  background-color: var(--mantine-color-blue-5);
  font-weight: bold;
  color: white;
  &:hover {
    background-color: var(--mantine-color-blue-6);
  }
}
