@import "./_mantine.scss";
.button {
  background-color: var(--mantine-color-pink-6);
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 0 var(--mantine-spacing-xs);
  min-width: 120px;
  &:active {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-blue-8) !important;
  }
  &:hover {
    background-color: var(--mantine-other-pinkHover);
    color: white;
  }
  &:disabled {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-4);

    svg {
      color: var(--mantine-color-gray-4);
    }
  }
}

.subscriptionButton {
  background-color: var(--mantine-color-lime-5);
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-md);
  font-weight: 700;

  &:hover {
    background-color: var(--mantine-color-blue-8);
    color: white;
  }

  svg {
    rotate: 125deg;
  }
}
