@import "./_mantine.scss";
.sessionCostSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: var(--mantine-spacing-md);
}

.icon {
  margin-top: 4px;
}

.priceHeader {
  color: var(--mantine-color-blue-8);
}

.price {
  color: var(--mantine-color-blue-6);

  &.freeTotal {
    color: var(--mantine-color-pink-6);
  }
}
