@import "./_mantine.scss";
.selectedClassesTimes p {
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;

  &:first-of-type::after {
    content: '-';
    margin-left: 8px;
  }
}

.spoilerControl {
  text-align: start;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-sm);
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 700;
  width: 100%;
  text-underline-offset: 6px;
  @media (max-width: $mantine-breakpoint-sm) {
    padding-left: 4px;
  }
}
.postTrialSessions {
  color: var(--mantine-color-gray-5);
}

.soldOut {
  text-decoration: line-through;
}
