@import "./_mantine.scss";
.modalCloseButton {
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 2;
  background-color: #e1e1e2;
  path {
    color: var(--mantine-color-gray-6);
  }
  &:hover {
    background-color: transparent;
  }
  height: 32;
  width: 32;
}

.modalContent {
  background: transparent;
  box-shadow: none;
  text-align: center;
}

.modalBody {
  background-color: white;
  margin: 1rem;
  border-radius: 24px;
}

.drawerCloseButton {
  position: sticky;
  top: 0;
  right: 24px;
  color: var(--mantine-color-blue-8);
  background-color: white;
  z-index: 9999;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 9999;
  border-radius: 0;

  span {
    margin: 24px 0;
    justify-content: flex-end;

    overflow: visible;
  }
}

.header {
  padding-bottom: 0;
}

.drawerContent {
  height: auto;
  max-height: 85vh;
  border-top-right-radius: var(--mantine-radius-sm);
  border-top-left-radius: var(--mantine-radius-sm);
}

.drawerBody {
  padding: 0 24px 20px;
}
