@import "./_mantine.scss";
.categoryName {
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-sm);
}
.background {
  background: var(--mantine-color-blue-8);
  border-radius: 4px;
}
.deselectedState {
  background: none;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: var(--mantine-color-gray-5);
    }
  }
  &:hover {
    background: none;
  }
}
.selectedState {
  background: none;
  color: var(--mantine-color-blue-6);
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: var(--mantine-color-blue-6);
    }
  }
}
.alignText {
  text-align: center;
}
.mediumSizeText {
  font-size: var(--mantine-font-size-md);
}
