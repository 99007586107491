@import "./_mantine.scss";
.columnHeader {
  color: var(--mantine-color-blue-8);
  margin-bottom: var(--mantine-spacing-md);
  width: 100%;
}

.consentBoxWrapper {
  padding: var(--mantine-spacing-lg) 0 0;
  max-width: 100%;
}

.proceedToPaymentNotice {
  color: var(--mantine-color-blue-8);
  margin: var(--mantine-spacing-sm) 0;
  font-size: var(--mantine-font-size-xs);
}

.taxFreeBlurb {
  margin: var(--mantine-spacing-lg) var(--mantine-spacing-sm) var(--mantine-spacing-xs);
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  text-align: center;
  line-height: var(--mantine-font-size-sm);
}

.wrapperWithPonchoPay {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: 130px;
  }
}

.supplierLinkConsent {
  label {
    padding-bottom: 0;
  }
}

.nextButton {
  height: fit-content;
  padding: 10px;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 700;
  background-color: var(--mantine-color-lime-5);
  color: var(--mantine-color-blue-8);

  width: 90vw;

  span {
    overflow: visible;
  }

  &:hover {
    background-color: var(--mantine-color-blue-8);
    color: white;

    svg {
      fill: white;
    }
  }

  @media (min-width: $mantine-breakpoint-sm) {
    width: 662px;

    margin-top: 4px;
  }
}

.price {
  color: var(--mantine-color-blue-6);

  &.freeTotal {
    color: var(--mantine-color-pink-6);
  }
}
