@import "./_mantine.scss";
.locationMessage {
  display: flex;
  margin: var(--mantine-spacing-lg);
  margin-left: 0;
  flex-wrap: wrap;
  font-size: 12px;
  @media (max-width: $mantine-breakpoint-sm) {
    margin-left: var(--mantine-spacing-lg);
  }
}

.updateLocation {
  line-height: 18px;
  margin-left: var(--mantine-spacing-sm);
  white-space: nowrap;
  align-items: center;
  font-size: 12px;
}

.grid {
  margin: 0;
}

.noActivitiesText {
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-md);
}

.registerLink {
  text-align: center;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-sm);
}

.tipBox {
  color: var(--mantine-color-blue-8);
  background: var(--mantine-color-lime-1);
  border-radius: var(--mantine-radius-sm);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--mantine-spacing-md);
  flex-wrap: nowrap;
  box-shadow: 0px 4px 24px rgba(11, 2, 78, 0.1);
  margin-block: var(--mantine-spacing-xl);
  svg {
    padding-top: 2px;
  }
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-md);
    svg {
      margin-top: -14px;
      width: auto;
    }
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.registerText {
  color: var(--mantine-color-blue-8);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-md);
  @media (min-width: $mantine-breakpoint-sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.affix {
  text-align: left;
  @media (min-width: $mantine-breakpoint-sm) {
    text-align: center;
  }
}

.noActivitiesWrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    width: 85%;
    margin: 0 auto;
  }
}

.onlineSwitch {
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
