@import "./_mantine.scss";
.controls {
  transition: opacity 150ms ease;
  opacity: 0;
  position: absolute;
  top: 40%;
  @media (min-width: 1520px) {
    top: 30%;
  }

  button {
    height: 48px;
    width: 48px;
    background: var(--mantine-color-gray-7);
    opacity: 0.4;
    border: none;
    &:hover {
      background: var(--mantine-color-pink-6);
      opacity: 1;
    }
    @media (max-width: $mantine-breakpoint-sm) {
      height: 32px;
      width: 32px;
    }
    svg {
      color: white;
      @media (min-width: $mantine-breakpoint-sm) {
        height: 30px;
        width: 30px;
      }
    }
  }
}
.root {
  &:hover {
    .controls {
      opacity: 1;
    }
  }
}
.indicator {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  &[data-active] {
    background: var(--mantine-color-gray-7);
    border: 0.5px solid white;
    height: 8px;
    width: 8px;
  }
}
