@import "./_mantine.scss";
.radioLabel {
  padding-left: var(--mantine-spacing-xs);
  color: var(--mantine-color-blue-8);
}

.infoText {
  margin-bottom: var(--mantine-spacing-md);
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  line-height: '18px';
  font-size: var(--mantine-font-size-sm);
}

.highlightedText {
  font-weight: 700;
}

.trialBanner {
  position: relative;
  border-radius: 4px;
  color: var(--mantine-color-blue-8);
  padding: var(--mantine-spacing-lg) var(--mantine-spacing-xs) var(--mantine-spacing-xs);
  margin-block: var(--mantine-spacing-xl);
}
.freeTrialBanner {
  background-color: var(--mantine-color-green-1);
  border: 1px solid var(--mantine-color-green-6);
}
.paidTrialBanner {
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-8);
}
