@import "./_mantine.scss";
.modal {
  padding: 0;
  color: var(--mantine-color-blue-8);
  overflow: hidden;
}
.modalBody {
  @media (max-width: $mantine-breakpoint-sm) {
    max-height: 100dvh;
    height: 100dvh;
  }
}
.modalInner {
  z-index: 201;
}
.modalBodyContent {
  height: auto;
  @media (max-width: $mantine-breakpoint-sm) {
    border-radius: 0;
  }
}
.container {
  width: 100%;
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    overflow: auto;
  }
  > * {
    max-width: none;
  }
}
.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: var(--mantine-color-gray-5);
  z-index: 2;
  width: 32px;
  height: 32px;
  &:hover {
    background-color: var(--mantine-other-blueHover);
  }
  @media (max-width: $mantine-breakpoint-sm) {
    background-color: var(--mantine-color-white);
  }
}
.modalContent {
  flex: 1;
  padding: 40px 20px 0 40px;
  text-align: center;
  position: relative;
  width: 85%;
  margin: 0 auto;

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 20px 0 0 0;
    width: 100%;
  }
}
.activityHeader {
  text-align: left;

  h1 {
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--mantine-color-gray-6);
    line-height: 24px;
  }

  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    color: var(--mantine-color-blue-8);
  }

  @media (max-width: $mantine-breakpoint-sm) {
    padding-inline: 20px;
  }
}
.separator {
  @media (max-width: $mantine-breakpoint-sm) {
    width: 90%;
  }
}
.activityHeaderDateTime {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-6);
  margin-top: var(--mantine-spacing-xs);
  font-weight: 600;

  div {
    line-height: 18px;
  }
}
.ticketsWrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    max-height: 100dvh;
  }

  max-height: 70dvh;
}
.ticketsWrapperNoErrorMassage {
  @media (max-width: $mantine-breakpoint-sm) {
    max-height: 100dvh;
  }
}
.ticketsWrapperNoAffix {
  max-height: none;
  @media (max-width: $mantine-breakpoint-sm) {
    max-height: none;
  }
}
.bookButtonPrice {
  margin-left: var(--mantine-spacing-md);
}
.noSpacesMessage {
  display: flex;
  flex-direction: row;
  padding: var(--mantine-spacing-sm);
  background: var(--mantine-color-blue-1);
  width: 75%;
  margin: 0 auto;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}
.noSpacesMessageText {
  font-size: var(--mantine-font-size-sm);
  max-width: 85%;
  padding-left: var(--mantine-spacing-sm);
}
.notificationIcon {
  background: var(--mantine-color-yellow-1);
  margin: 0 8px;
}
.notificationRoot {
  border: 1px solid var(--mantine-color-yellow-8);
  border-radius: 4px;
  background: var(--mantine-color-yellow-1);
  width: 100%;
  margin: var(--mantine-spacing-md) auto 0;
  font-size: 12px;
  box-shadow: none;
  padding: var(--mantine-spacing-xs) 0;
  @media (max-width: $mantine-breakpoint-sm) {
    width: calc(100dvw - 40px);
    margin: 0 auto;
  }
}
.notificationTitle {
  color: var(--mantine-color-blue-8);
  text-align: left;
  font-size: 12px;
}
.notificationCloseBtn {
  color: var(--mantine-color-blue-8);
  @media (max-width: $mantine-breakpoint-sm) {
    align-items: flex-start;
  }

  &:hover {
    background-color: transparent;
  }
}
.bookNowContainer {
  bottom: 20px;
  width: 100%;
  padding-top: var(--mantine-spacing-lg);
  padding-bottom: var(--mantine-spacing-lg);
  margin: 0 auto;
  background-color: var(--mantine-color-white);

  @media (max-width: $mantine-breakpoint-sm) {
    width: 90%;
    padding-top: var(--mantine-spacing-md);
    padding-bottom: 0;
  }

  button {
    @media (max-width: $mantine-breakpoint-sm) {
      height: 40px;
      font-size: 16px;
    }
  }
}
.affixContainer {
  background: white;
  padding-bottom: var(--mantine-spacing-md);
}
.timeVaryBadge {
  margin-top: var(--mantine-spacing-xs);
  background: var(--mantine-color-blue-1);
  width: fit-content;
  border-radius: 8px;
  padding: 6px;
  font-size: var(--mantine-font-size-xs);
  font-weight: 700;
  color: var(--mantine-color-blue-8);
}
.totalCost p {
  font-size: var(--mantine-font-size-lg);
  line-height: 24px;
}

.totalCostText {
  color: var(--mantine-color-blue-6);
}
.totalCostFree {
  color: var(--mantine-color-pink-6);
}

.ticketsColumn {
  flex-grow: 1;
  overflow: auto;
  padding: 20px 20px 32px 20px;

  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: 0px;
    overflow: inherit;
    padding: 20px;
    margin-left: 0;
  }
}

.sessionInformation {
  flex-grow: 1;
  justify-content: flex-start;
  text-align: start;

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 20px;
  }
}

.selectedClassesTimes p {
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;

  &:first-of-type::after {
    content: '-';
    margin-left: 8px;
  }
}
