@import "./_mantine.scss";
.card {
  background-color: #fff;
  border-radius: 24px;
  padding: 0;
  margin: 0 auto 24px;
  overflow: hidden;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
}

.cardHeader {
  overflow: hidden;
  margin: var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  background-color: var(--mantine-color-gray-1);
  padding: var(--mantine-spacing-xs) 0;
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0;
  }
}
.cardHeaderInner {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.imgContainer {
  border-radius: var(--mantine-radius-sm);
  width: 75px;
  height: 75px;
  min-width: 75px;
  overflow: hidden;
}

.cardTitles {
  color: var(--mantine-color-blue-8);
  border-bottom: 1px solid var(--mantine-color-gray-2);

  h2 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: var(--mantine-spacing-xs);
    line-height: 1.25rem;
    @media (min-width: $mantine-breakpoint-sm) {
      line-height: 1.5rem;
      font-size: 20px;
    }
  }

  h3 {
    font-size: 12px;
    margin-top: 4px;
    color: var(--mantine-color-gray-5);
    line-height: 1.25rem;

    @media (min-width: $mantine-breakpoint-sm) {
      font-size: 20px;
      margin-top: var(--mantine-spacing-xs);
      line-height: 1.5rem;
    }
  }
}

.activityLocation {
  font-size: var(--mantine-font-size-xs);
  margin-top: 4px;
  color: var(--mantine-color-gray-6);
  font-weight: 600;

  svg {
    color: var(--mantine-color-gray-6);
  }
}

.details {
  color: var(--mantine-color-blue-8);
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-lg) var(--mantine-spacing-lg);
  font-weight: 600;
  gap: 10px;
}

.subsDetails {
  padding: 0 var(--mantine-spacing-lg) var(--mantine-spacing-lg);
}

.detailsCol {
  gap: 12px;
  font-size: var(--mantine-font-size-xs);

  svg {
    margin-right: var(--mantine-spacing-xs);
  }
}

.dateAndTime {
  width: fit-content;
}

.timeVaryBadge {
  padding: 0 8px;
  background: var(--mantine-color-blue-1);
  border-radius: 8px;
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  color: var(--mantine-color-blue-8);
}

.timeVaryIcon {
  svg {
    margin-right: 0 !important;
  }
}

.iconCheck {
  margin-right: var(--mantine-spacing-sm);
}

.waitlistBadge {
  color: var(--mantine-color-blue-8);
  background: var(--mantine-color-blue-2);
  border-radius: 4px;
  text-transform: none;
  margin-bottom: var(--mantine-spacing-xs);
}
