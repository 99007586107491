@import "./_mantine.scss";
.verifyButton {
  margin-top: 6px;
  margin-left: -2px;
  padding-inline: 0;
  color: var(--mantine-color-blue-6);
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  &:hover {
    background: none;
    text-decoration: underline;
  }
}

.verifyButtonIcon {
  margin-right: 4px;
}

.modal {
  background: transparent;
  padding: var(--mantine-spacing-md);
  position: relative;
  box-shadow: none;
}

.modalBody {
  background: white;
  border-radius: 1rem;
  padding: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-lg);
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 2px;
  box-shadow: 1px 1px 12px rgba(120, 121, 123, 0.5);
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-gray-5);
  z-index: 202;
  &:hover {
    background-color: var(--mantine-other-blueHover);
    color: white;
  }
}

.title {
  color: var(--mantine-color-blue-5);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: var(--mantine-spacing-xs);
}

.description {
  text-align: center;
  color: var(--mantine-color-blue-8);
}

.activityDetailsPage {
  text-decoration: none;
  font-weight: 600;
}
