@import "./_mantine.scss";
.rootBadge {
  background-color: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-6);
  border-radius: 4px;
  border: none;
}
.innerBadge {
  span {
    text-transform: capitalize;
  }
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
}
.trialBadge {
  background-color: var(--mantine-color-green-6);
  color: var(--mantine-color-white);
}
.paidTrialBadge {
  background-color: var(--mantine-color-blue-8);
  color: var(--mantine-color-white);
}

.cornerBadge {
  border-radius: 4px;
  border-top-left-radius: 0;
  font-size: var(--mantine-font-size-xs);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xs);
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
}
