@import "./_mantine.scss";
.thankyouSection {
  background-image: url('/new_gradient_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 170px;
  margin: 0 -10%;
  padding-bottom: 0px;
  margin-top: var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-sm) {
    height: 180px;
  }
}
.thankyouText {
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 700;
}
.wrapper {
  margin: 0 var(--mantine-spacing-md) 0;
  text-align: start;
  padding-left: var(--mantine-spacing-lg);
  padding-right: var(--mantine-spacing-lg);

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0;
  }

  p {
    color: var(--mantine-color-blue-8);
    line-height: 18px;
    margin: 0px;
  }
  h4 {
    color: var(--mantine-color-blue-8);
    font-weight: 700;
    // padding-block: var(--mantine-spacing-sm);
  }
  h5 {
    color: var(--mantine-color-blue-8);
    margin-top: var(--mantine-spacing-md);
    margin-bottom: var(--mantine-spacing-xs);
    font-weight: 700;
  }
  a {
    font-size: var(--mantine-font-size-sm);
    margin-bottom: var(--mantine-spacing-lg);
  }
}
.headerWrapper {
  background-color: #07113d;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 36px 0;
  position: relative;

  & > svg {
    margin: 0 auto;
  }

  & .scribbles {
    position: absolute;
    right: 10%;
  }
}
.card {
  max-width: 500px;
  margin: var(--mantine-spacing-xl) auto;
  box-shadow: 0px 4px 24px rgba(14, 32, 111, 0.08);
  border-radius: 24px;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  font-weight: 400;
  svg {
    margin-right: var(--mantine-spacing-xs);
  }
}
.tableLine {
  padding: 0px;
  border-bottom: 1px solid var(--mantine-color-blue-1);
}
.bookingDetailsInfo {
  text-align: end;
  color: var(--mantine-color-blue-8);
  font-weight: 700;
}
.priceSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.badge {
  background-color: var(--mantine-color-lime-5);
  border-radius: 8px;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-xs);
  padding: 10px 7px;
  text-transform: capitalize;
}
.price {
  color: var(--mantine-color-blue-6);
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  padding-left: var(--mantine-spacing-sm);
}
.footer {
  text-align: center;
}
.secondaryLink {
  color: var(--mantine-color-blue-5);
}
.ticket {
  fill: var(--mantine-color-white);
  stroke: var(--mantine-color-blue-8);
  stroke-width: 1px;
}
.ticketDetails {
  white-space: break-spaces;
}
.timeVaryLabel {
  color: var(--mantine-color-blue-8);
  width: fit-content;
  text-transform: none;
}

.timeVaryRoot {
  padding: 6px 0 6px 6px;
  svg {
    margin-right: 2px !important;
  }
}

.freeSession {
  color: var(--mantine-color-gray-5);
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}
.fullPageContainer {
  background-image: url('/checkout-bg.jpg');
  text-align: center;
  width: 100%;
  height: calc(100dvh - 84px);
  color: var(--mantine-color-blue-8);
  padding-left: var(--mantine-spacing-xl);
  padding-right: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    height: calc(100dvh - 48px);
  }
}
.errorImage {
  background-image: url('/clouds_bg.jpg');
  min-width: 229px;
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid var(--mantine-color-gray-2);
  display: flex;
  align-items: center;
}
.free {
  color: var(--mantine-color-pink-5);
}

.dateIcon {
  align-self: start;
}

.scheduleCard {
  .activityName {
    font-size: 1.5rem;
    line-height: 36px;
  }

  .informationCard {
    padding: 20px;
    box-shadow: 0px 4px 24px rgba(14, 32, 111, 0.08);
    border-radius: 24px;
    font-size: var(--mantine-font-size-sm);
    color: var(--mantine-color-blue-8);
    font-weight: 400;
    svg {
      margin-right: var(--mantine-spacing-xs);
    }

    @media (max-width: $mantine-breakpoint-sm) {
      padding: 16px;
    }
  }

  .scheduleName {
    font-size: 1.5rem;
    line-height: 24px;

    @media (max-width: $mantine-breakpoint-sm) {
      font-size: var(--mantine-font-size-lg);
    }
  }

  .totalCost {
    font-size: 1.5rem;

    @media (max-width: $mantine-breakpoint-sm) {
      font-size: var(--mantine-font-size-xl);
    }
  }

  .childInfoCard {
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: 12px;
  }

  .listOfSessions p {
    color: var(--mantine-color-blue-8);
    font-size: var(--mantine-font-size-sm);
    font-weight: 600;
  }
}

.viewMoreLess {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

p.sessionsTimes {
  @media (max-width: $mantine-breakpoint-sm) {
    font-weight: 700 !important;
  }
}

.editAttendeeInfo {
  background: var(--mantine-color-blue-1);
  border-radius: 8px;
  padding: 12px;
  margin-top: 28px;
}