@import "./_mantine.scss";
.ratingsBar {
  text-align: right;
  white-space: nowrap;

  @media (max-width: $mantine-breakpoint-sm) {
    text-align: left;
  }

  span {
    font-size: 14px !important;

    &:nth-of-type(1) {
      color: var(--mantine-color-blue-8) !important;
      font-weight: 600;
    }

    &:nth-of-type(3) {
      color: var(--mantine-color-gray-5) !important;
      font-weight: 500;

      &::after {
        content: ')';
        color: var(--mantine-color-gray-5) !important;
        position: absolute;
        right: -5px;
        top: -3px;
      }

      &::before {
        content: '(';
        color: var(--mantine-color-gray-5) !important;
        position: absolute;
        left: -4.5px;
        top: -3px;
      }
    }
  }
}
.companyRatingsBar {
  width: fit-content;
  margin: calc(var(--mantine-spacing-xl) * 2) auto var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-sm);
  background: transparent;
  border-radius: var(--mantine-radius-lg);
  cursor: pointer;
  @media (max-width: $mantine-breakpoint-sm) {
    margin: var(--mantine-spacing-xs) auto;
  }
}
