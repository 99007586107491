@import "./_mantine.scss";
.image {
  position: absolute;
  width: 100%;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.card {
  background-color: #fff;
  border-radius: 12px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0px 4px 15px 0px #15254e1f;

  @media (max-width: $mantine-breakpoint-sm) {
    max-height: auto;
  }
}
.defaultCard {
  border-radius: 24px 24px 12px 12px;
}
.content {
  background-color: var(--mantine-color-gray-2);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.item {
  width: auto;
  margin: 0 auto;
  min-height: 54px;
  position: relative;
  margin-top: var(--mantine-spacing-sm);
  border-bottom: 0px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 1rem;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 4px;
    border-image-source: linear-gradient(to right, #95ea3b, #ff2c9e, #4378ff);
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    height: 4px;
    width: calc(100% - 2rem);
    opacity: 0.5;
    @media (min-width: $mantine-breakpoint-sm) {
      display: none;
    }
  }
}
.itemActive {
  &:before {
    display: none;
  }
}
.chevron {
  color: var(--mantine-color-blue-8);
  &[data-rotate] {
    transform: none;
  }
  width: auto;
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: 4px;
  }
}
.control {
  padding: 1rem;
  padding-top: 0px;
  align-items: flex-end;
  transition: background-color 100ms linear;
  &:hover {
    background-color: var(--mantine-color-white);
  }
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: 0px;
  }
}
.controlActive {
  background-color: var(--mantine-color-gray-2);
  &:hover {
    background-color: var(--mantine-color-gray-2);
  }
  @media (min-width: $mantine-breakpoint-sm) {
    position: absolute;
    width: auto;
    right: 0px;
    padding-top: 0.75rem;
  }
}
.label {
  padding-bottom: 0px;

  &[data-rotate] {
    display: none;
  }
}
.badge {
  background: var(--mantine-color-red-8);
  color: var(--mantine-color-red-1);
  border-radius: 6px;
  text-transform: none;
  font-size: 10px;
  font-weight: 700;
  padding: 8px;
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1;
  @media (min-width: $mantine-breakpoint-sm) {
    top: -12px;
  }
}
.limitedSpotsBadge {
  color: var(--mantine-color-white);
  background-color: var(--mantine-color-blue-6);
}
.popularBadge {
  color: var(--mantine-color-white);
  background-color: var(--mantine-color-pink-8);
}
.justAddedBadge {
  color: var(--mantine-color-white);
  background-color: #148867;
}
