@import "./_mantine.scss";
.listRoot {
  margin-bottom: var(--mantine-spacing-md);
}
.itemWrapper {
  width: 100%;
  margin-top: 0;
  span {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.listItem {
  margin-bottom: 2px;
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  svg {
    width: 16px;
    height: 16px;
    margin-right: var(--mantine-spacing-xs);
  }
}
.weekLabel {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  margin-bottom: var(--mantine-spacing-xs);
}
.dateGridRoot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gridCol {
  margin-left: 0;
  min-width: 'fit-content';
}
.past {
  color: var(--mantine-color-gray-5);
  line-height: 14px;
  padding: 2px 0;
}
.pastDate {
  text-decoration: line-through;
}
.viewButton {
  text-decoration: none;
  color: var(--mantine-color-blue-6);
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  svg {
    font-weight: 700;
    width: 14px;
    height: 14px;
    color: var(--mantine-color-blue-6);
  }
}
.soldOut {
  text-decoration: line-through;
  color: var(--mantine-color-red-8);
}
.available {
  svg {
    color: var(--mantine-color-green-4);
  }
}
.open {
  svg {
    rotate: 180deg;
  }
}
.timeVaryBadge {
  background: var(--mantine-color-blue-1);
  border-radius: 8px;
  padding: 0 4px;
  margin-left: 8px;
  width: max-content;
  display: flex;
  align-items: center;

  p {
    font-size: var(--mantine-font-size-xs);
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.clockIcon {
  margin-left: 4px;
}
.wrapper {
  padding-top: 0;
}
.dateAndTime {
  white-space: nowrap;
}
.dayOfMonth {
  text-align: center;
}
