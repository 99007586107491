@import "./_mantine.scss";
.title {
  color: var(--mantine-color-blue-8);
  font-size: 20px;
}

.optionalTitle {
  color: var(--mantine-color-gray-6);
  font-size: 20px;
}

.accordionItem {
  box-shadow: 0px 4px 24px 0px #00000014;
  padding: 16px 16px 16px 16px;
  margin-bottom: var(--mantine-spacing-lg);
  border-radius: var(--mantine-radius-md);
  border-bottom: 0;
  &[data-active] {
    padding: 16px 16px 0 16px;
  }
}

.accordionItemOpen {
  padding: 16px 16px 0 16px !important;
}

.accordionChevron {
  margin-top: 6px;
}
.accordionControl {
  align-items: flex-start;
  padding-inline: 0;
  span {
    font-weight: 700;
    padding-block: 0;
  }
}

.addOnCard {
  border: 1px solid var(--mantine-color-gray-4);
  border-radius: 12px;
  margin-bottom: var(--mantine-spacing-md);
  padding: 4px 12px 8px 12px;
}

.addButton {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 0;
  background-color: white;
  color: var(--mantine-color-blue-6);

  &:hover {
    background-color: white;
    color: var(--mantine-color-blue-6);
  }
}

.leftSection {
  margin-right: 4px;
}

.description {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--mantine-color-gray-6);
}

.price {
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  line-height: 18px;
  color: var(--mantine-color-blue-8);
}

.accordionContent {
  padding: 16px 0 2px 0;
}

.addOnsStepWrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: 85px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.content {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.header {
  padding: 16px 16px 0 24px;
  display: flex;
  align-items: flex-start;
}

.close {
  background-color: var(--mantine-color-gray-4);
  color: var(--mantine-color-gray-7);

  svg {
    height: 15px !important;
    width: 15px !important;
  }
}

.body {
  padding-bottom: 0;
}

.addOnName {
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-gray-6);
  margin: 8px 8px 0 0;
  font-weight: 600 !important;
  font-size: 12px;
}

.addOnNameWrapper {
  flex-grow: 1;
}

.textAreaInput {
  border: none;
  font-weight: 600;
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-blue-8);
  &::placeholder {
    color: var(--mantine-color-gray-5);
  }
}

.selectAllCheckbox {
  font-weight: 700;
}

.closeIcon {
  background: #e1e1e2;
  border-radius: 16px;
  z-index: 9999;

  svg {
    width: 24;
    height: 24;
    font-weight: 700;
  }
}

.addItemButton {
  background: var(--mantine-color-pink-6);
  color: white;
  border-radius: var(--mantine-spacing-xs);
  stroke-width: 4;
  border: none;
  &:disabled {
    background: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-6);
  }
  // //to resolve mobile hover state bug
  @media (min-width: $mantine-breakpoint-lg) {
    &:hover {
      background: var(--mantine-color-pink-6);
      color: white;
    }
  }
}
.minusItemButton {
  margin-right: var(--mantine-spacing-xs);
  background: var(--mantine-color-pink-2);
  color: var(--mantine-color-pink-5);
  border-radius: var(--mantine-spacing-xs);
  border: none;
  stroke-width: 3;
  &:disabled {
    background: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-6);
  }
  //to resolve mobile hover state bug
  @media (min-width: $mantine-breakpoint-lg) {
    &:hover {
      background: var(--mantine-color-pink-2);
      color: var(--mantine-color-pink-5);
    }
  }
}

.buttonWrapper {
  margin-right: var(--mantine-spacing-sm);
  min-width: 5rem;
}

.priceLabel {
  padding: var(--mantine-spacing-xs);
  justify-content: flex-start;
  background: var(--mantine-color-blue-1);
  border-radius: var(--mantine-spacing-sm);
  min-width: 10rem;
  color: var(--mantine-color-blue-8);
  text-align: left;
}

.addRemoveContainer {
  justify-content: flex-end;
  @media (max-width: $mantine-breakpoint-md) {
    justify-content: space-between;
    width: 100%;
  }
}

.nextButtonWrapper {
  justify-content: center;
  @media (max-width: $mantine-breakpoint-sm) {
    background-color: white;
    position: fixed;
    padding: 24px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.divider {
  border-bottom: 2px dashed var(--mantine-color-gray-4);
  padding-inline: 16px;
}
