@import "./_mantine.scss";
.selectButton {
  width: 83px;
  background-color: #c6f991;
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: var(--mantine-font-size-xs);
  height: var(--mantine-font-size-xl);

  &:active &:focus {
    background-color: #c6f991;
  }

  &:hover {
    background-color: var(--mantine-color-blue-8);
  }
}
.removeButton {
  background-color: #7f839b;
  color: #fff;

  &:active &:focus {
    background-color: #7f839b;
  }
}
.labelWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.waitlistButton {
  font-weight: 700;
  height: var(--mantine-font-size-xl);
  min-width: 120px;
  background-color: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
  svg {
    color: var(--mantine-color-blue-8);
  }
  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-8);
    svg {
      color: white;
    }
  }
}

.sessionItem {
  border-bottom: 0.9px dotted var(--mantine-color-gray-3);
  padding: 10px 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.amountOfBasketSchedules {
  background-color: var(--mantine-color-pink-6);
  border-radius: 50%;
  padding: 0 6px;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
