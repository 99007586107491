@import "./_mantine.scss";
.box {
  background-color: var(--mantine-color-blue-1);
  border-radius: 4px;
  border: 1px solid var(--mantine-color-blue-6);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xs);
  color: var(--mantine-color-blue-8);
  text-align: start;
  @media (max-width: $mantine-breakpoint-sm) {
    margin: var(--mantine-spacing-sm) 20px;
  }
}
.icon {
  min-width: 32px;
  @media (max-width: $mantine-breakpoint-sm) {
    min-width: 24px;
  }
}
