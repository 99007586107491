@import "./_mantine.scss";
.pinkText {
  color: var(--mantine-color-pink-5);
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xl);
  }
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.alignCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: var(--mantine-spacing-lg);
  }
}

.logoImage {
  margin-bottom: var(--mantine-spacing-lg);
  width: max-content;
  height: 63px;
}

.cardHeading {
  font-weight: 700;
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-pink-5);
}

.description {
  color: var(--mantine-color-gray-8);
  font-size: var(--mantine-font-size-sm);
}

.cardFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: white;
  box-shadow: 0px 4px 24px rgba(11, 23, 78, 0.1);
  border-radius: var(--mantine-spacing-lg);
  padding: var(--mantine-spacing-lg);
  align-self: stretch;
  width: 30%;
  @media (max-width: 820px) {
    width: 90%;
  }
  @media (min-width: 1470px) {
    width: 310px;
    height: 330px;
  }
}

.greenRectangle {
  display: flex;
  justify-content: center;
  margin: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    margin: 0;
  }
}

.wrapper {
  margin: var(--mantine-spacing-xl) auto 0 auto;
  @media (min-width: $mantine-breakpoint-sm) {
    width: 70%;
  }
}
