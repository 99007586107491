@import "./_mantine.scss";
.basketWrapper {
  background-color: white;
  padding: var(--mantine-spacing-md) 40px 0px 40px;
  box-shadow: 0px -4px 24px 0px #00000014;
  border-radius: 24px 24px 0 0;
  z-index: 150;

  @media (max-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-sm);
    background-color: var(--mantine-color-gray-1);
    box-shadow: none;
  }
}

.basketScheduleAmount {
  color: white;
  font-weight: 700;
}

.basketScheduleAmountAboveTen {
  border-radius: 20px;
}

.checkoutButton {
  background-color: var(--mantine-color-lime-5);
  color: var(--mantine-color-blue-8);
  border-radius: 60px;
  font-weight: 700;
  font-size: 16px;
  overflow: visible;

  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-lg);
    width: 100%;
  }

  &:disabled {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-4);

    svg {
      color: var(--mantine-color-gray-4);
    }
  }

  &:hover {
    background: var(--mantine-color-blue-8);
    color: white;
    svg {
      fill: white;
    }
  }
}
.checkoutButtonInner {
  width: max-content;
  margin: 0 auto;
}

.ticketCard {
  padding: 12px;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border-radius: 4px;
  width: 228px;

  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
    border-radius: 10px;
  }
}

.hoverState {
  &:hover {
    cursor: pointer;
  }
}

.timeVaryBadge {
  background: var(--mantine-color-blue-1);
  width: fit-content;
  border-radius: 4px;
  padding: 0 8px;
}

.pinkText {
  color: var(--mantine-color-pink-5);
}

.sessionList {
  flex-grow: 1;
  gap: 8px;
  display: flex;
  align-items: stretch;
}

.sessionsOverflow {
  flex-grow: 1;
  max-width: calc(100% - 150px - 8px);
}

.minimiseCta {
  color: var(--mantine-color-blue-6);
  font-weight: 700;
}

.accordionItem {
  border: none;
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 20px;
  }
}

.accordionContent {
  padding: 0;
}

.accordionRoot {
  @media (min-width: $mantine-breakpoint-sm) {
    box-shadow: 0px -4px 24px 0px #00000014;
    padding: 0 53px 0 76px;
  }
}
.accordionMobileRoot {
  @media (max-width: $mantine-breakpoint-sm) {
    height: auto;
    max-height: 100dvh;
    overflow-y: scroll;
    padding: 0;
  }
}
.accordionChevron {
  @media (min-width: $mantine-breakpoint-sm) {
    display: none;
  }
}

.accordionControl {
  &:hover {
    background-color: transparent;
  }
}

.accordionPanel {
  @media (min-width: $mantine-breakpoint-sm) {
    padding-bottom: 16px;
  }
}

.disableControlClick {
  pointer-events: none;
}

.basketScheduleAmountRoot {
  height: 36px;
}
