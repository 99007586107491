@import "./_mantine.scss";
.cardWrapperFull {
  position: relative;
  width: 240px;
}

.cardWrapperMin {
  position: relative;
  width: 365px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}

.card {
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--mantine-color-gray-2);
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  &:hover {
    cursor: pointer;
    .bookPseudoButton {
      color: white;
      background-color: var(--mantine-color-blue-8);
    }
  }
}

.dateInfo {
  p {
    line-height: 22px;
    font-size: var(--mantine-font-size-xs);
  }
}

.fullSizeCard {
  background-color: #f7f8fa;
}

.minifiedCard {
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px #0000001f;
}

.defaultCard {
  border-radius: 12px;
}

.cardBody {
  padding: 8px 10px 10px;
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}

.cardHeader {
  height: 133px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 12px;
}

.image {
  position: absolute;
  width: 100%;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.imageBorder-radius {
  img {
    border-radius: 12px;
  }
}

.minCardImage {
  margin-top: 6px;
  width: 48px;
  height: 48px;
  img {
    border-radius: 4px;
  }
}

.badge {
  background: var(--mantine-color-red-8);
  color: var(--mantine-color-red-1);
  text-transform: none;
  font-size: 10px;
  padding-inline: var(--mantine-spacing-xs);
  position: absolute;
  top: -10px;
  right: 8px;
  border-radius: 4px;
  font-weight: 700;
  z-index: 2;
  height: 20px;
}

.limitedSpotsBadge {
  color: white;
  background-color: var(--mantine-color-blue-6);
}

.popularBadge {
  color: white;
  background-color: var(--mantine-color-pink-8);
}

.justAddedBadge {
  color: white;
  background-color: #148867;
}

.activityTitle {
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-md);
  font-weight: 700;
  text-decoration: none;
  text-align: left;
}

.fixedSectionWrapper {
  width: 216px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  gap: 0.3rem;
}

.bookPseudoButton {
  height: 36px;
  border-radius: 6px;
  color: var(--mantine-color-blue-8);
  background-color: var(--mantine-color-lime-5);
  padding: 0 var(--mantine-spacing-xs);
  min-width: 120px;
  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-8) !important;
  }
}

.waitlistPseudoButton {
  background-color: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-6);
  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-6) !important;
  }
}
