@import "./_mantine.scss";
.card {
  box-shadow: 0px 4px 24px rgba(11, 23, 78, 0.1);
  border-radius: 24px;
  margin: 0;
  padding-inline: var(--mantine-spacing-md);
  @media (min-width: $mantine-breakpoint-sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: var(--mantine-spacing-lg);
    a {
      min-width: 35%;
    }
  }
}
.unavailable {
  opacity: 0.7;
  color: var(--mantine-color-gray-6);
}
.ticketName {
  font-weight: 700;
  font-size: var(--mantine-font-size-md);
  line-height: 22px;
  color: var(--mantine-color-blue-8);
  margin-top: 0;
  margin-bottom: 0;
}
.ticketPrice,
.ticketPrice > p {
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
}

.ticketPriceSubs {
  font-weight: 600 !important;
}

.freeTicket {
  color: var(--mantine-color-pink-5);
  font-weight: 700;
}
.ticketDescription {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-6);
  padding-right: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    padding-right: 0;
  }
}
.ticketInfoWrapper {
  text-align: left;
  margin-bottom: var(--mantine-spacing-md);
  max-width: 400px;
}
.addButton {
  background: var(--mantine-color-pink-6);
  color: white;
  border-radius: var(--mantine-spacing-xs);
  stroke-width: 4;
  border: none;
  &:disabled {
    background: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-6);
  }
  @media (min-width: $mantine-breakpoint-lg) {
    &:hover &:not([disabled]) {
      background: var(--mantine-color-pink-6);
      svg: {
        color: var(--mantine-color-blue-5);
      }
    }
  }
}
.minusButton {
  background: var(--mantine-color-pink-2);
  color: var(--mantine-color-pink-5);
  border-radius: var(--mantine-spacing-xs);
  border: none;
  stroke-width: 3;
  &:disabled {
    background: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-6);
  }
  //to resolve mobile hover state bug
  @media (min-width: $mantine-breakpoint-lg) {
    &:hover &:not([disabled]) {
      background: var(--mantine-color-pink-2);
      color: var(--mantine-color-pink-5);
    }
  }
}
.numberInput {
  background: white;
  width: 200px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-top: 4px;
  }
}
.rightSection {
  width: 165px;
}
.attendeesLabel {
  padding: var(--mantine-spacing-xs);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background: var(--mantine-color-blue-1);
  border-radius: var(--mantine-spacing-sm);
  min-width: 200px;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  text-align: left;
}
.growLabel {
  flex-grow: 1;
}
.input {
  background: var(--mantine-color-blue-1);
  border: none;
  border-radius: var(--mantine-spacing-sm);
  color: var(--mantine-color-blue-8);
  font-weight: 800;
  font-size: var(--mantine-font-size-sm);
}
.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  @media (min-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    align-items: flex-end;
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.bookButtonPrice {
  margin-left: var(--mantine-spacing-md);
}
.cancelCopy {
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-xs);
  font-style: italic;
}
.cancelWrapper {
  width: 100%;
  text-align: left;
}
.cancelCopyBold {
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-6);
}

.priceInfoButton {
  padding-left: 1px;
  color: var(--mantine-color-blue-8);
  .mantine-Button-section {
    margin-left: 4px;
  }
}

.priceInfoTooltip {
  white-space: wrap;
  box-shadow: 0px 4px 24px rgba(11, 23, 78, 0.1);
  padding: var(--mantine-spacing-lg);
}

.priceInfoTooltipContent {
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-gray-6);
  max-width: 470px;
}

.rootBadge {
  padding: 2px 4px;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-blue-8);
  border-radius: 4px;
  text-transform: none;
  border-color: var(--mantine-color-blue-8);
}
.innerBadge {
  font-size: 10px;
  font-weight: 700;
}
.trialBadge {
  color: var(--mantine-color-green-6);
  border-color: var(--mantine-color-green-6);
  background-color: var(--mantine-color-white);
}
.paidTrialBadge {
  color: var(--mantine-color-blue-8);
  background-color: var(--mantine-color-white);
}
.closeButton {
  cursor: pointer;
  background-color: var(--mantine-color-gray-5);
  z-index: 2;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: var(--mantine-other-blueHover);
  }
  @media (max-width: $mantine-breakpoint-sm) {
    background-color: var(--mantine-color-white);
  }
}
