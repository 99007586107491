@import "./_mantine.scss";
.table {
  display: table;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  svg {
    width: 16px;
    height: 16px;
  }
}
.tableRow {
  display: table-row;
}
.soldOut {
  text-decoration: line-through;
  color: var(--mantine-color-red-8);
}
.tableCell {
  display: table-cell;
  padding: 6px;
  white-space: nowrap;
}
.bold {
  font-weight: 700;
}
.spoilerControl {
  margin: 0 auto;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  width: 100%;
  text-decoration: underline;
  text-underline-offset: 6px;
}
