@import "./_mantine.scss";
.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-gray-6);
  cursor: pointer;
  z-index: 999;
}
.ponchoPayLogo {
  margin-left: var(--mantine-spacing-xs);
}
.modalTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  padding: var(--mantine-spacing-sm) 60px 0;
  color: var(--mantine-color-blue-5);
}
.modalHeader {
  display: block;
  padding-bottom: 0;
}
.modalBody {
  color: var(--mantine-color-blue-8);
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);
}
.ponchoInfo {
  text-align: left;
}
