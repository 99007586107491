@import "./_mantine.scss";
.header {
  margin-left: -24px;
  margin-right: -24px;
  color: var(--mantine-color-blue-8);
}
.headerText {
  margin: 0 0 0 24px;
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
  padding-top: 8px;
  padding-bottom: 4px;
}
.subheaderText {
  margin: 0 0 8px 24px;
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-5);
}
.labelWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.viewAllDates {
  width: 100%;
  border-bottom: 1px dotted var(--mantine-color-gray-3);
  padding-bottom: 10px;

  &:last-of-type {
    border-bottom: none;
  }
}

// --- Shared styles ---
.waitlist {
  color: var(--mantine-color-blue-6);
  font-weight: 700;
  font-size: var(--mantine-font-size-xs);
}
.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  li {
    margin: var(--mantine-spacing-md) 0;
  }
}
.timeDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.detailsItem {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: var(--mantine-spacing-xs);
  p {
    font-size: var(--mantine-font-size-md);
    margin: 0;
    margin-left: var(--mantine-spacing-xs);
    color: var(--mantine-color-blue-8);
  }
  svg {
    color: var(--mantine-color-blue-5);
    text-align: center;
  }
}
.sessionSpots {
  margin-right: 0.5rem;
  font-weight: 600;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-spacing-sm);
}
.timeVaryBadge {
  background: var(--mantine-color-blue-1);
  width: fit-content;
  border-radius: 8px;
  padding: 0 8px;
}
