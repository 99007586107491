@import "./_mantine.scss";
.wrapper {
  background-image: url('/gradientVioletBg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: var(--mantine-spacing-xl);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  @media (max-width: $mantine-breakpoint-sm) {
    height: fit-content;
  }
}
.heading {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
  text-align: center;
  margin-bottom: var(--mantine-spacing-xl);
  margin-top: var(--mantine-spacing-xl);
  color: var(--mantine-color-blue-8);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 0px;
    margin-bottom: var(--mantine-spacing-md);
  }
}
.flexRow {
  display: flex;
  height: fit-content;
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}
.emptyDiv {
  width: 20%;
}
.textWrapper {
  width: 36%;
  @media (max-width: $mantine-breakpoint-md) {
    width: 100%;
    text-align: center;
  }
}
.imageWrapper {
  position: relative;
  width: 50%;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}
.imageContainer {
  position: absolute;
  bottom: -5px;
  right: 0px;
  @media (max-width: $mantine-breakpoint-sm) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    bottom: 0px;
  }
  @media (min-width: 1150px) {
    img {
      max-height: 35vh;
    }
  }
}
.subheading {
  color: var(--mantine-color-blue-8);
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: var(--mantine-spacing-lg);
  }
}
.textCopy {
  color: #5d6277;
  font-size: var(--mantine-font-size-md);
  font-weight: 700;
  line-height: 22px;
  margin-bottom: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    max-width: 80%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto;
    margin-bottom: var(--mantine-spacing-xl);
    text-align: center;
  }
}
.greenRectangle {
  display: flex;
  justify-content: center;
  margin: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    margin: 0px;
  }
}
