@import "./_mantine.scss";
.card {
  border: 1px solid var(--mantine-color-gray-2);
  border-radius: 12px;
  margin: var(--mantine-spacing-sm) 0 var(--mantine-spacing-xs) 0;
}
.calendarCell {
  &[data-today] {
    font-weight: 700;
  }
}
.ticketHeader {
  margin-left: var(--mantine-spacing-sm);
  margin-right: var(--mantine-spacing-xs);
  font-size: var(--mantine-font-size-md);
  font-weight: 700;
  color: var(--mantine-color-blue-8);
}
.accordionPanel {
  padding: 0px;
}
.input {
  color: var(--mantine-color-blue-8);
  background-color: white;
  border: var(--mantine-color-blue-8) 1px solid;
  border-radius: 12px;
  font-size: var(--mantine-font-size-md);
  height: 40px;
  padding-left: var(--mantine-spacing-md);
  &:placeholder {
    color: var(--mantine-color-gray-6);
    font-weight: 600;
  }
  &:disabled {
    background-color: white;
    opacity: 1;
  }
  &[data-expanded] {
    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-gray-5);
    border-top: 4px solid var(--mantine-color-blue-2);
    border-left: 4px solid var(--mantine-color-blue-2);
    border-right: 4px solid var(--mantine-color-blue-2);
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.inputLabel {
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
  margin-bottom: var(--mantine-spacing-xs);
}
.required {
  color: var(--mantine-color-blue-8);
}
.dropdown {
  padding: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-left: 4px solid var(--mantine-color-blue-2);
  border-right: 4px solid var(--mantine-color-blue-2);
  border-bottom: 4px solid var(--mantine-color-blue-2);
  background-color: var(--mantine-color-gray-1);
  &:hover {
    cursor: pointer;
  }
}
.item {
  border-radius: 0px;
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  align-items: center;
  :not(:first-child) {
    white-space: nowrap;
  }
  &:last-of-type {
    border-top: 1px solid var(--mantine-color-gray-4);
    color: var(--mantine-color-blue-6);
  }
  &:hover {
    background-color: var(--mantine-color-blue-1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.partiallyDisabledItem {
  &[data-combobox-disabled] {
    opacity: 1;
    p {
      opacity: 0.35;
    }
  }
}
.subheaderText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--mantine-color-blue-8);
  font-weight: 400;
  align-items: baseline;
  font-size: var(--mantine-font-size-sm);
}
.consentBoxWrapper {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.additionalPadding {
  padding-top: var(--mantine-spacing-md);
}
.attendeeList {
  color: var(--mantine-color-gray-6);
}
.errorWarning {
  border: 1px solid var(--mantine-color-red-5);
  border-radius: 12px;
}

.newAttendeeSelectFocused {
  outline: 4px solid var(--mantine-color-blue-2);
  border: none;
  &:focus {
    outline: 4px solid var(--mantine-color-blue-2);
    border: none;
  }
}
.inputSelectFocused {
  transform: rotate(180deg);
}

.hoverCardDropdown {
  border-radius: 3px;
  background-color: var(--mantine-color-blue-8);
  color: #fff;
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  white-space: break-spaces !important;
}

.hoverCardArrow {
  background-color: var(--mantine-color-blue-8);
}

.radio {
  border: var(--mantine-color-blue-5) 2px solid;
  &:checked {
    background: var(--mantine-color-lime-5);
    border: var(--mantine-color-blue-5) 5px solid;
    height: 20px;
    width: 20px;
    z-index: 2;
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
  }
}
