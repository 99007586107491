@import "./_mantine.scss";
.fullWidth {
  justify-content: space-between;
  border: 1px solid var(--mantine-color-gray-2);
  border-radius: 24px;
  align-items: baseline;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.daysOfWeek {
  font-size: var(--mantine-font-size-xs);
  font-weight: 400;
  color: var(--mantine-color-gray-5);
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
}
.dayOfWeekSelected {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
}
.limeBg {
  background-color: var(--mantine-color-lime-2);
  border-radius: 24px;
  border: 1px solid var(--mantine-color-lime-6);
}
.enlarged {
  font-size: var(--mantine-font-size-sm);
  width: 29px;
  height: 29px;
  line-height: 18px;
}
