@import "./_mantine.scss";
.icon {
  &:hover {
    path: {
      fill: var(--mantine-other-blueHover) !important;
    }
    cursor: pointer;
  }
}

.copy {
  font-size: 14px;
  color: var(--mantine-color-blue-8);
}
