@import "./_mantine.scss";
.bottom {
  @media (max-width: $mantine-breakpoint-sm) {
    display: block;
    font-size: 96px;
    padding-top: var(--mantine-spacing-xl);
  }
}

.top {
  @media (max-width: $mantine-breakpoint-sm) {
    display: block;
    font-size: 96px;
  }
}

.boldQuote {
  font-size: 44px;
  font-weight: bold;
  line-height: 80px;
  text-align: center;
  color: var(--mantine-color-blue-8);
  @media (max-width: $mantine-breakpoint-sm) {
    line-height: 60px;
  }
}

.quoteOwner {
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin-bottom: var(--mantine-font-size-xl);
  margin-top: var(--mantine-font-size-md);
  color: #5d6277;
  font-weight: bold;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-lg);
  }
}

.center {
  margin: 0 auto;
  margin-bottom: calc(var(--mantine-spacing-lg) * 2);
  @media (max-width: $mantine-breakpoint-sm) {
    width: 90%;
    margin-bottom: 0;
  }
}
