@import "./_mantine.scss";
.pinkHeader {
  text-align: center;
  font-size: 48px;
  color: var(--mantine-color-pink-5);
  padding-top: calc(var(--mantine-spacing-lg) * 2);
  padding-bottom: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xl);
    padding-bottom: var(--mantine-spacing-sm);
    padding-top: var(--mantine-spacing-md);
  }
}

.greenRectangle {
  display: flex;
  justify-content: center;
  margin: var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-sm) {
    margin: 0;
  }
}

.subheading {
  color: var(--mantine-color-blue-8);
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  padding-top: var(--mantine-spacing-lg);
  padding-bottom: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 22px;
    padding-top: var(--mantine-spacing-sm);
    margin-left: var(--mantine-spacing-xl);
    margin-right: var(--mantine-spacing-xl);
  }
}
