@import "./_mantine.scss";
.spoilerControl {
  text-align: start;
  color: var(--mantine-color-blue-6);
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  width: 100%;
  text-underline-offset: 6px;
}

.sessionDateInfo {
  p {
    font-size: var(--mantine-font-size-sm);
    font-weight: 600;
    line-height: 18px;
  }
}
