@import "./_mantine.scss";
.title {
  color: var(--mantine-color-blue-8);
  font-size: 20px;
}

.phonePrefix {
  color: var(--mantine-color-blue-8);
  padding-left: var(--mantine-spacing-sm);
  z-index: 0;
}

.descriptionText {
  margin-top: 4px;
  line-height: 16px;
  color: var(--mantine-color-blue-8);
}
