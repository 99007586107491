@import "./_mantine.scss";
.container {
  margin-top: var(--mantine-spacing-sm);
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--mantine-spacing-md);
  max-width: 1002px;
  @media (min-width: 820px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: var(--mantine-spacing-sm);
    margin-bottom: 0;
    width: 662px;
  }
}

.nextButton {
  @media (min-width: $mantine-breakpoint-md) {
    margin-top: var(--mantine-spacing-xs);
  }

  padding: 11px 138px 12px 138px;
  border-radius: 60px;
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  background-color: var(--mantine-color-pink-6);

  span {
    overflow: visible;
  }

  &:disabled {
    background-color: var(--mantine-color-pink-5) !important;
    opacity: 0.45;
    color: var(--mantine-color-white) !important;
  }

  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }
}

.nextButtonWrapper {
  justify-content: center;
  @media (max-width: $mantine-breakpoint-sm) {
    background-color: white;
    position: fixed;
    padding: 24px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.ponchoPayButton {
  width: 100%;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 700;
  color: var(--mantine-color-pink-5);
  border: 1px solid var(--mantine-color-pink-5);
}

.ponchoPayLogo {
  margin-left: var(--mantine-spacing-xs);
}

.hiddenIcon {
  display: hidden;
}

.stepIcon {
  background: var(--mantine-color-gray-2);

  &[data-progress] {
    background: white;
    border: 2px solid var(--mantine-color-blue-6);
  }

  &[data-completed] {
    background: var(--mantine-color-blue-6);
    border: 2px solid var(--mantine-color-blue-6);
  }
}
.stepperContent {
  padding-top: var(--mantine-spacing-lg);
}
.step {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--mantine-spacing-xs);
  font-weight: 700;
  font-size: 12px;

  span {
    font-weight: 700;
    font-size: 12px;
    color: var(--mantine-color-gray-5);
  }

  &[data-progress] {
    span {
      color: var(--mantine-color-blue-8);
    }
  }

  &[data-completed] {
    span {
      color: var(--mantine-color-blue-8);
    }
  }
}

.separator {
  background-color: transparent;
  border-top: 2px dashed var(--mantine-color-gray-2);
  margin-bottom: 20px;
  margin-left: -2rem;
  margin-right: -2rem;
  &[data-active] {
    background-color: var(--mantine-color-blue-6);
    border-top: none;
  }
}

.root {
  margin-top: var(--mantine-spacing-md);
}

.taxFreeBlurb {
  margin: 0 var(--mantine-spacing-sm) 0;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  text-align: center;
  line-height: var(--mantine-font-size-lg);
}
