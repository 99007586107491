@import "./_mantine.scss";
.subInfoWrapper {
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-6);
  color: var(--mantine-color-blue-8);
  padding: 12px 8px;
  border-radius: 4px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin: 0 48px;
    text-align: left;
    padding: 12px;
  }
}

.subDescription {
  p {
    margin: 12px 0 0 0;
  }
}

.pagination {
  font-weight: 800;
  svg {
    width: 24;
    height: 24;
    stroke: var(--mantine-color-blue-8);
    stroke-width: 0.8;
  }
}

.month {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  font-size: var(--mantine-font-size-md);
}

.dayOfWeek {
  border-bottom: 1px var(--mantine-color-gray-3) solid;
  padding-bottom: var(--mantine-spacing-sm);
}

.monBold {
  &:nth-child(1) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.tueBold {
  &:nth-child(2) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.wedBold {
  &:nth-child(3) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.thuBold {
  &:nth-child(4) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.friBold {
  &:nth-child(5) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.satBold {
  &:nth-child(6) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.sunBold {
  &:nth-child(7) {
    font-weight: 600;
    color: var(--mantine-color-blue-8);
  }
}

.levelsGroup {
  display: flex;
  justify-content: center;
}

.calendarWrapper {
  background-color: white;
  padding: var(--mantine-spacing-lg);
  border-radius: 4px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  display: inline-block;
  margin: var(--mantine-spacing-md) auto var(--mantine-spacing-md);
  padding-bottom: var(--mantine-spacing-xs);

  @media (max-width: $mantine-breakpoint-sm) {
    box-shadow: none;
    width: 100%;
    margin: 0 auto;
  }
}

.day {
  border-top: none;
  color: var(--mantine-color-gray-5);

  &:hover {
    background: none !important;
  }

  &[data-weekend] {
    color: var(--mantine-color-gray-5);

    &:disabled {
      color: #ced4da !important;
    }
  }

  &[data-selected] {
    background-color: var(--mantine-color-gray-2) !important;
    color: var(--mantine-color-gray-8) !important;
  }

  &[data-today] {
    font-weight: 700;
  }
}

.calendarKey {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-md) var(--mantine-spacing-xl)
    var(--mantine-spacing-md);
  max-width: 322px;
  margin: 0 auto var(--mantine-spacing-md);

  @media (max-width: 768px) {
    max-width: 266px;
    padding: var(--mantine-spacing-md) var(--mantine-spacing-md) 0 var(--mantine-spacing-md);
  }

  div {
    margin: 0 8px 0 0;
  }
}

.flexKey {
  display: flex;
  align-items: center;
}

.activeDateKey {
  background-color: var(--mantine-color-lime-5);
  border-radius: 4px;
  height: 15px;
  width: 15px;
}

.offDaysKey {
  background-color: var(--mantine-color-gray-4);
  border-radius: 4px;
  height: 15px;
  width: 15px;
}

.activityDays {
  background-color: var(--mantine-color-lime-5);
  color: var(--mantine-color-blue-8);
  border-radius: 0;
  font-weight: 600;

  &:hover {
    background-color: var(--mantine-color-lime-5) !important;
    color: var(--mantine-color-blue-8) !important;
  }
}

.offDates {
  background-color: var(--mantine-color-gray-4) !important;
  color: var(--mantine-color-blue-8) !important;
  border-radius: 0;
  font-weight: 600;
  &:hover {
    background-color: var(--mantine-color-gray-4) !important;
    color: var(--mantine-color-blue-8) !important;
  }
}
