@import "./_mantine.scss";
.root {
  overflow: hidden;
  @media (max-width: 1325px) {
    margin: 6px 20px 0 0;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    margin: 6px 20px 0 0;
  }
}
.separator {
  width: 10px;
  @media (max-width: $mantine-breakpoint-sm) {
    margin: 0 4px;
  }
}
.item {
  line-height: var(--mantine-font-size-sm);
  white-space: nowrap;
  &:last-child {
    flex-grow: 1;
    min-width: 0;
  }
  &:nth-of-type(5) {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.itemText {
  font-size: var(--mantine-font-size-sm);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xs);
    text-overflow: ellipsis;
    whitespace: nowrap;
    overflow: hidden;
  }
}
.itemLink {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-6);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xs);
    text-overflow: ellipsis;
    whitespace: nowrap;
    overflow: hidden;
  }
}
