@import "./_mantine.scss";
.heading {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
  line-height: 16px;
  margin-top: var(--mantine-spacing-sm);

  @media (max-width: 991px) {
    margin-left: 24px;
  }

  @media (max-width: $mantine-breakpoint-md) {
    margin-left: 0;
  }
}
.calendar {
  background-color: white;
  margin-top: var(--mantine-spacing-md);
  display: flex;
  justify-content: center;
}
.calendarHeader {
  margin-bottom: 0;
}
.calendarMonth {
  font-weight: 600;
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
}
.calendarPagination {
  color: var(--mantine-color-blue-8);
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: var(--mantine-color-blue-8);
      stroke-width: 0.75px;
    }
  }
}
.weekday {
  font-size: var(--mantine-font-size-xs);
  padding-bottom: var(--mantine-spacing-xs);
  border-bottom: 1px var(--mantine-color-gray-3) solid;
}
.dayCell {
  border-top: none;
}
.selectedDay {
  &:not([disabled]) {
    border-radius: 0;
    background-color: var(--mantine-color-blue-8);
    color: white;
    margin: 0;
    font-weight: 600;

    &:hover {
      background-color: var(--mantine-color-blue-8);
    }
  }
}
.available {
  background-color: var(--mantine-color-lime-2);
  border-color: var(--mantine-color-lime-2);
  color: var(--mantine-color-blue-8);
  border-radius: 0;
  font-weight: 600;
}
.soldOutDate {
  background-color: var(--mantine-color-red-1);
  color: var(--mantine-color-red-8);
  font-weight: 600;
}

.inBasket {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 7px;
    height: 7px;
    background-color: var(--mantine-color-pink-6);
    border-radius: 50%;
    z-index: 15;
  }
}
