@import "./_mantine.scss";
.container {
  height: 186px;
  align-items: stretch;
  gap: 4px;
  border-radius: 6px;
  overflow: hidden;
}

.image1 {
  min-width: 216px;
  flex-grow: 1;
  overflow: hidden;
  img {
    width: calc(100% + 92px);
    min-height: 100%;
    @media (min-width: $mantine-breakpoint-sm) {
      height: 100%;
    }
  }
}

.singleImage {
  min-width: 311px;
  img {
    width: 100%;
    min-width: 311px;
  }
}

.imageStack {
  gap: 4px;
  min-width: 92px;
  div {
    flex-grow: 1;
    display: flex;
  }
}
