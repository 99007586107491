@import "./_mantine.scss";
.scheduleCard {
  .activityName {
    font-size: 1.5rem;
    line-height: 36px;
  }

  .informationCard {
    padding: 20px;
    box-shadow: 0px 4px 24px rgba(14, 32, 111, 0.08);
    border-radius: 24px;
    font-size: var(--mantine-font-size-sm);
    color: var(--mantine-color-blue-8);
    font-weight: 400;
    svg {
      margin-right: var(--mantine-spacing-xs);
    }

    @media (max-width: $mantine-breakpoint-sm) {
      padding: 16px;
    }
  }

  .scheduleName {
    font-size: 1.5rem;
    line-height: 24px;

    @media (max-width: $mantine-breakpoint-sm) {
      font-size: var(--mantine-font-size-lg);
    }
  }

  .totalCost {
    font-size: 1.5rem;

    @media (max-width: $mantine-breakpoint-sm) {
      font-size: var(--mantine-font-size-xl);
    }
  }

  .childInfoCard {
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: 12px;
  }
}
