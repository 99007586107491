@import "./_mantine.scss";
.title {
  color: var(--mantine-color-blue-8);
  font-size: 20px;
}

.clearBtn {
  color: var(--mantine-color-blue-6);
  padding: 0;
  width: fit-content;
  height: fit-content;
  font-size: var(--mantine-font-size-sm);
  &:hover {
    background-color: 'none';
    text-decoration: underline;
  }
}

.attendeeConfirmed {
  color: #0f634a;
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
}

.attendeeStepWrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: 55px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.classCard {
  margin-block: var(--mantine-font-size-xl);
  box-shadow: 0px 4px 24px rgba(11, 23, 78, 0.1);
  border-radius: 12px;
}
.samsungFix {
  &:last-of-type {
    margin-bottom: 200px;
  }
}

.ticketAccordionContent {
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: var(--mantine-spacing-sm);
  }
}

.classAccordionChevron {
  width: 24px;
  height: 24px;
}

.ticketAccordionChevron {
  width: 18px;
  height: 18px;
}
