@import "./_mantine.scss";
.hideButton {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  @media (max-width: $mantine-breakpoint-sm) {
    padding-inline: 0px;
    div {
      justify-content: space-between;
    }
  }
  &:hover {
    background-color: var(--mantine-color-gray-2);
  }
}
.item {
  border-bottom: none;
}
.control {
  max-width: fit-content;
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  color: var(--mantine-color-gray-6);
  padding-inline: 0px;
  &:hover {
    background-color: var(--mantine-color-gray-2);
  }
}
.distanceInfo {
  color: var(--mantine-color-pink-8);
}
.chevron {
  @media (max-width: $mantine-breakpoint-sm) {
    position: absolute;
    right: 16px;
  }
  margin-left: var(--mantine-spacing-sm);
  &[data-rotate] {
    display: none;
  }
}
.icon {
  margin-right: var(--mantine-spacing-xs);
}
.content {
  padding-inline: 0px;
  padding-bottom: var(--mantine-spacing-lg);
}
.moreSubheader {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: var(--mantine-color-blue-6);
  margin-top: var(--mantine-spacing-md);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.supplierPageButton {
  background-color: var(--mantine-color-blue-8);
  font-weight: 600;
  margin-top: 24px;
  &:hover {
    background-color: var(--mantine-other-blueHover);
  }
}
