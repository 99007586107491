@import "./_mantine.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-bottom: calc(var(--mantine-spacing-xl) * 3);
  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-xl);
  }
}

.heading {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
  text-align: center;
  margin-bottom: var(--mantine-spacing-xl);
  color: var(--mantine-color-pink-5);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: var(--mantine-spacing-md);
  }
}

.subheading {
  color: var(--mantine-color-blue-8);
  font-weight: bold;
  font-size: 42px;
  line-height: 54px;
  margin-bottom: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xl);
    line-height: 28px;
    text-align: center;
    margin-top: var(--mantine-spacing-md);
    margin-bottom: 0;
  }
}

.textCopy {
  color: #5d6277;
  font-size: var(--mantine-font-size-md);
  font-weight: bold;
  margin-bottom: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    maxwidth: 65%;
    margin: 24px auto;
    text-align: center;
  }
}

.greenRectangle {
  display: flex;
  justify-content: center;
  margin: var(--mantine-spacing-lg);
}
