@import "./_mantine.scss";
.modal {
  padding: 0px;
  color: var(--mantine-color-blue-8);
  overflow: hidden;
  height: 515px;
}
.modalBody {
  height: 100%;
}
.container {
  width: 100%;
  height: 100%;
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
  > * {
    max-width: none;
  }
}
.imageWrapper {
  flex: 1;
  flex-grow: 1;
  max-width: 350px;
  height: 100%;
  background-color: #07113d;
  padding: 32px 48px;
  overflow: hidden;
  & svg {
    margin-left: -15px;
  }
}

.listingsImages {
  max-width: 65%;
}

.logoWrapper {
  position: absolute;
  right: 0px;
  bottom: -10px;
  @media (max-width: $mantine-breakpoint-sm) {
    display: none;
  }
}
.modalContent {
  flex: 1;
  padding: 95px 51px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  background-image: url('/createAccountModalImages/pebbleShapes.webp');
  background-position: bottom left;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  @media (max-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-sm);
    background-color: #07113d;
    background-image: none;
  }
}

.closeButtonWrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  background-color: var(--mantine-color-gray-2);

  &:hover {
    background-color: var(--mantine-color-blue-8);
  }

  @media (max-width: $mantine-breakpoint-sm) {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.closeButtonIcon {
  color: var(--mantine-color-gray-6);
  z-index: 2;
  padding: 2px;

  &:hover {
    color: white;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    color: white;
  }
}

.logInButton {
  text-decoration: underline;
  @media (max-width: $mantine-breakpoint-sm) {
    color: white !important;
  }
}
