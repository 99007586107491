@import "./_mantine.scss";
.shareButton {
  display: flex;
  justify-content: flex-end;
  @media (max-width: $mantine-breakpoint-sm) {
    margin-right: var(--mantine-spacing-sm);
  }
}
.label {
  @media (max-width: $mantine-breakpoint-sm) {
    display: none;
  }
  color: var(--mantine-color-blue-6);
  &:hover {
    text-decoration: underline;
  }
}
