@import "./_mantine.scss";
.pin {
  margin-right: var(--mantine-spacing-xs);
}

.label {
  padding: 0;
  color: var(--mantine-color-gray-6);
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xs);
    padding-left: 14px;
    padding-right: 14px;
  }
}

.chevron {
  width: auto;
}

.control {
  padding: var(--mantine-spacing-md) 20px 16px 20px;
  width: 75%;
  margin: 0 auto;
  &:hover {
    background-color: white;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}

.icon {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-right: 0;
  }
}

.item {
  margin-bottom: var(--mantine-spacing-md);
  border-bottom: none;
  background: white;
  position: relative;
  padding-bottom: 0;

  &[data-active] {
    padding-bottom: var(--mantine-spacing-xl);
  }

  @media (max-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-xs);
  }
}

.panel {
  width: 75%;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}

.content {
  padding-inline: 0;
}

.distanceFromLabel {
  color: var(--mantine-color-pink-8);
  &:after {
    content: '•';
    display: inline-block;
    color: var(--mantine-color-gray-6);
    margin-left: var(--mantine-spacing-xs);
    margin-right: var(--mantine-spacing-xs);
  }
}

.carouselIndicators {
  bottom: -30px;
}

.carouselIndicator {
  width: 12px;
  height: 12px;
  border-radius: var(--mantine-radius-sm);
  background-color: var(--mantine-color-blue-2);
  transition: width 250ms ease;

  &[data-active] {
    width: 40px;
    background-color: var(--mantine-color-blue-6);
  }
}

.carouselControl {
  margin: 0 -45px;
  display: flex;
  justify-content: space-between;
  button {
    height: 25px;
    width: 25px;
    background: white;
    border: 1px solid var(--mantine-color-gray-3);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem,
      rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem,
      rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--mantine-color-gray-6);
    }
  }
}

.carouselViewport {
  overflow: hidden;
}

.carouselContainer {
  padding-top: 10px;
}
