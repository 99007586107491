@import "./_mantine.scss";
.soldOut {
  text-decoration: line-through;
  color: var(--mantine-color-red-8);
}

.spoilerControl {
  text-align: start;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  width: 100%;
  text-underline-offset: 6px;
  text-decoration: underline;
}
.sessionDateInfo {
  p {
    font-size: var(--mantine-font-size-sm);
    font-weight: 600;
    line-height: 18px;
  }
  color: var(--mantine-color-blue-8);
}
.postTrialSessions {
  color: var(--mantine-color-gray-5);
}
