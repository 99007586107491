@import "./_mantine.scss";
.closeButton {
  background-color: var(--mantine-color-gray-2);
  z-index: 2;
  margin: var(--mantine-spacing-sm);
  width: 24px;
  height: 24px;
  &:hover {
    background-color: var(--mantine-color-gray-5);
  }
}
.modalContent {
  background: transparent;
  box-shadow: none;
  text-align: center;
}
.modalBody {
  background-color: white;
  margin: 1rem;
  border-radius: 24px;
}
.contentWrapper {
  display: flex;
  align-items: center;
  color: var(--mantine-color-blue-8);
  gap: 0;
  text-align: center;
}
.modalTitle {
  font-weight: 700;
  font-size: 20px;
  padding-inline: var(--mantine-spacing-sm);
  color: var(--mantine-color-blue-6);
}
